<template>
  <v-container>
    <v-card
      class="mt-5"
      flat
    >
      <v-row>
        <v-col>
          <v-card>
            <v-toolbar
              color="white"
              class="elevation-0"
            >
              <v-toolbar-title>Mi Perfil <v-icon>person</v-icon></v-toolbar-title>
              <v-spacer />
              <v-btn
                color="success"
                @click="grabarDatos"
              >
                Guardar<v-icon right>
                  send
                </v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider />
            <v-row class="mt-1">
              <v-col
                cols="6"
                class="mx-3"
              >
                <v-text-field
                  v-model="getdatosUsuario.nomuser"
                  prepend-icon="person"
                  label="Nombre de usuario"
                />
                <v-text-field
                  v-model="getdatosUsuario.email"
                  prepend-icon="email"
                  label="Correo"
                />
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="getdatosUsuario.telefono"
                      prepend-icon="local_phone"
                      label="Teléfono"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="getdatosUsuario.celular"
                      prepend-icon="phone_iphone"
                      label="Celular"
                    />
                  </v-col>
                </v-row>
                <v-text-field
                  v-model="getdatosUsuario.empresa"
                  prepend-icon="location_city"
                  label="Empresa"
                />
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="getdatosUsuario.departamento"
                      prepend-icon="work"
                      label="Departamento"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="getdatosUsuario.sucursal"
                      prepend-icon="domain"
                      label="Sucursal"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="5">
                <v-row>
                  <v-col>
                    <v-row>
                      <v-col>
                        <v-card class="elevation-0 transparent">
                          <!-- <v-img :src="imagen1" height="150" contain aspect-ratio="2" v-if="Persona.Avatarurl"/>
                        <v-img src="soporte1.png" height="150" contain aspect-ratio="2" v-else/> -->

                          <v-card-text>
                            <v-file-input
                              v-model="files"
                              :rules="rulesPick"
                              accept="image/png, image/jpeg, image/bmp"
                              placeholder="Selecciona una foto de perfil"
                              prepend-icon="mdi-camera"
                              label="Avatar"
                              @change="subirfoto"
                            />
                          
                            <v-row justify="center">
                              <v-btn
                                color="success"
                                small
                                @click="subirfoto"
                              >
                                Subir Imagen
                              </v-btn>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default{
  data(){
    return{

      files:[],
      // avatar:[],
      url:'',
      nuevoUrl:'',
      nombreImagen:'',
      size:'',

      editar: true,
      password2:'',
      snackbar: false,
    }
  },
  computed:{
    ...mapGetters('CargarApp', ['getPersona','getAvatar']),
    ...mapGetters('login', ['getdatosUsuario'])
  },
  
  created () {
    this.cargarAvatar()
  },
  
  methods:{
    ...mapActions('login',['saveInfoUsuario']),
    habilitar(){
      this.editar = !this.editar
      this.disabled = !this.disabled
    },

    grabarDatos(){
      this.saveInfoUsuario(this.getdatosUsuario).then(() => {
        this.snackbar = true;
      })
      this.editar = !this.editar
      this.disabled = !this.disabled
    }
  },
  
}
</script>